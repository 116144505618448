<template>
  <div class="body min-h-screen flex flex-col">
    <header
      class="p-4 flex justify-between items-center"
      style="font-size: 1.4rem"
    >
      <div class="flex items-center space-x-5">
        <img
          src="./assets/logo.png"
          alt="Cartoon POOP logo with red hat"
          class="w-full h-10 rounded-full"
        />
        <nav style="margin-left: 0.3rem !important">
          <ul class="flex space-x-10">
            <li><a href="/" class="text-white">HOME</a></li>
            <li style="margin-left: 1.2rem !important">
              
            </li>
          </ul>
        </nav>
      </div>
      <button
        class="bg-green-600 text-white px-3 py-2 rounded-full Connect-Wallet"
        @click="connectWallet"
        v-if="walletAddress == null"
        style="display:none;"
      >
        Connect Wallet
      </button>
      <div
        class="bg-green-600 text-white px-3 py-2 rounded-full Connect-Wallet"
        v-else
      >
        {{ walletAddress.slice(0, 5) + "***" + walletAddress.slice(-5) }}
        <button
          class="bg-green-600 text-white px-3 py-2 rounded-full Connect-Wallet"
          @click="disconnectWallet"
        >
          <i
            id="disconnect"
            class="icon iconfont icon-disconnect"
            style="font-size: 24px"
          ></i>
        </button>
      </div>
      <button
        class="bg-green-600 text-white px-3 py-2 rounded-full Wallet-Account hide-ele"
      >
        <span id="Wallet-Account"></span>
        <i
          id="disconnect"
          class="icon iconfont icon-disconnect"
          style="font-size: 24px"
        ></i>
      </button>
    </header>
    <!-- Mint Loading Modal -->
    <div v-if="isMinting" class="modal-overlay">
      <div class="modal-content">
        <p>Minting in progress...</p>
        <div class="loader"></div>
      </div>
    </div>

    <!-- Custom Message Modal -->
    <div v-if="showModal" class="modal" @click="closeModal1">
      <div class="modal-content" @click.stop>
        <span class="close" @click="closeModal1">&times;</span>
        <p>{{ modalMessage }}</p>
        <!-- <button class="close" @click="closeModal1">Close</button> -->
      </div>
    </div>

    <div id="walletDisconnectedModal" v-if="showDisconnetModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeModal('walletDisconnectedModal')"
          >&times;</span
        >
        <h2>Wallet Disconnected</h2>
        <p>Your wallet has been successfully disconnected.</p>
      </div>
    </div>

    <div
      id="mintingInProgressModal"
      class="modal"
      v-if="showmintingInProgressModal"
    >
      <div class="modal-content">
        <span class="close" @click="closeModal('mintingInProgressModal')"
          >&times;</span
        >
        <h2>Minting In Progress...</h2>
        <p>Please wait, minting is in progress...</p>
        <div class="loader"></div>
      </div>
    </div>

    <div
      id="mintingSuccessfulModal"
      class="modal"
      v-if="showmintingSuccessfulModal"
    >
      <div class="modal-content">
        <span class="close" @click="closeModal('mintingSuccessfulModal')"
          >&times;</span
        >
        <h2>Minting Successful</h2>
        <p>Congratulations! You have successfully minted.</p>
      </div>
    </div>

    <div id="alreadyMintedModal" class="modal" v-if="showalreadyMintedModal">
      <div class="modal-content">
        <span class="close" @click="closeModal('alreadyMintedModal')"
          >&times;</span
        >
        <h2>Already Minted</h2>
        <p>You have already minted for this address.</p>
      </div>
    </div>

    <div
      id="showPleaseConnectWalletModal"
      class="modal"
      v-if="showPleaseConnectWalletModal"
    >
      <div class="modal-content">
        <span class="close" @click="closeModal('showPleaseConnectWalletModal')"
          >&times;</span
        >
        <h2>Please Connect Wallet</h2>
      </div>
    </div>

    <main class="flex-grow container mx-auto px-10">
      <div class="text-center mb-8">
        <img src="./assets/banner.png" class="mx-auto mb-8" />
        <h1 class="text-6xl mb-10">GAS POOP</h1>
      </div>

      <div class="text-center">
        <img
          src="./assets/poop.gif"
          width="150px"
          height="150px"
          style="margin: 0 auto"
        />
        <p>
          $GASPOOP fuel ignites creativity, with poop skateboards showcasing joyful
          eco-adventures! 🌈💩
        </p>
        <img src="./assets/banner_B.png" />
      </div>

      <div style="display:none">
        <h2 class="text-4xl text-center mb-4 mint" style="color: #090909">
          MINT  $GASPOOP
        </h2>
        <input
          type="hidden"
          id="ca"
          value="XXXXXXXXXXXXXXXXXXXX"
        />
        <p class="text-center mb-8 text-sm" style="position: relative">
          CA: XXXXXXXXXXXXXXXXXXXX
          <span
            class="text-center mb-8 text-sm copy-btn"
            id="copy-btn"
            @click="copy"
          ></span>
        </p>
        <p class="text-center mb-4 text-sm" style="position: relative">
          Inspired by Ethereum's gas units (wei and Gwei), we introduce Poop. As
          pioneers of organic fuel, we reward you with $GASPOOP every time you burn
          gas on the Ethereum network.
        </p>
        <p
          class="text-center mb-2 text-sm"
          style="
            position: relative;
            padding-top: 10px;
            border-top: 3px dotted #000;
          "
          id="start_mint"
        >
          Minting Rules
        </p>
        <p class="text-center mb-2 text-sm" style="position: relative">
          Minting Limit: Each address can mint only once
        </p>
        <p class="text-center mb-2 text-sm" style="position: relative">
          Maximum Minting Amount: 5 × 10¹³ $GASPOOP
        </p>
        <p class="text-center mb-2 text-sm" style="position: relative">
          Minting Options
        </p>
        <p class="text-center mb-2 text-sm" style="position: relative">
          Select 1: 10¹³ $GASPOOP
        </p>
        <p class="text-center mb-2 text-sm" style="position: relative">
          Select 2: 2 × 10¹³ $GASPOOP
        </p>
        <p class="text-center mb-2 text-sm" style="position: relative">
          Select 3: 3 × 10¹³ $GASPOOP
        </p>
        <p class="text-center mb-2 text-sm" style="position: relative">
          Select 4: 4 × 10¹³ $GASPOOP
        </p>
        <p class="text-center mb-2 text-sm" style="position: relative">
          Select 5: 5 × 10¹³ $GASPOOP
        </p>
        <div class="bg-white text-black rounded-lg p-6 max-w-md mx-auto">
          <p>TotalSupply</p>
          <p class="mb-5">
            <span id="max_supply">{{ maxSupply }}</span> $GASPOOP
          </p>
          <div style="text-align: center">
            TOTAL MINTED
            <span style="margin-left: 30px" id="percent">{{
              percentMinted
            }}</span
            >%
          </div>
          <div class="container mb-4">
            <div id="progress-bar-container">
              <div id="progress-bar"></div>
            </div>
          </div>
          <div class="mb-4">
            <p>
              METHOD : MINT <span id="max_num">{{ num }}</span
              >0,000,000,000,000 $GASPOOP
            </p>
            <p>
              PRICE: <span id="pay_value">{{ calculatedPrice }}</span> ETH
            </p>
            <p>
              YOU HAVE :
              <span id="max_num">{{
                balance != null && balance != undefined
                  ? parseInt(balance).toFixed(0)
                  : balance
              }}</span>
            </p>
            <button
              class="bg-green-600 rounded-full text-white w-full py-2 mt-2"
              @click="mint"
            >
              MINT
            </button>
            <p class="mint-label">Select number of mints:</p>
            <div class="number-selector">
              <button
                v-for="num in [1, 2, 3, 4, 5]"
                :key="num"
                :class="[
                  'number-button',
                  `bg${num}`,
                  { selected: selectedAmount === num },
                ]"
                @click="selectAmount(num)"
              >
                {{ num }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>

    <footer class="mt-8 mb-2">
      <div
        class="bg-gradient-to-r from-red-500 via-yellow-500 to-blue-500 py-1 overflow-hidden"
      >
        <p class="text-center text-sm scrolling-text">
          Every time you make a transaction, you're injecting new vitality into
          our green planet. Let's ignite endless creativity and joy with $GASPOOP
        </p>
      </div>
      <div class="py-5 flex justify-between items-center px-10">
        <div class="flex-content">
          <img src="./assets/banner_C.png" max-height="300px" />
        </div>
        <div class="flex-content space-x-5">
          <div class="flex">
            <a
              target="_blank"
              href="https://x.com/gas_poop"
              style="margin-left: 5px"
            >
              <img alt="Twitter Logo" src="./assets/x.png" />
            </a>
            <a
              target="_blank"
              href="https://t.me/LFGPOOP"
              style="margin-left: 5px"
            >
              <img alt="Telegram Logo" src="./assets/tg.png" />
            </a>
          </div>
          <div class="text-right">
            <p class="text-black-500">GAS POOP</p>
            <p class="text-xs text-black-400">© 2024 GAS POOP</p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { ethers } from "ethers";
import MyToken from "./MyToken.json";

export default {
  data() {
    return {
      walletAddress: null,
      totalMinted: 0,
      percentMinted: 0,
      modalMessage: "",
      showModal: false,
      isMinting: false,
      maxSupply: ethers.BigNumber.from("1000000000000000000"),
      selectedAmount: 1,
      showDisconnetModal: false,
      showmintingInProgressModal: false,
      showmintingSuccessfulModal: false,
      showalreadyMintedModal: false,
      showPleaseConnectWalletModal: false,
      balance: 0,
      provider: null,
      signer: null,
      contract: null,
      networkId: "1",
    };
  },
  computed: {
    calculatedPrice() {
      return (0.0008 * this.selectedAmount).toFixed(4);
    },
    num() {
      return this.selectedAmount;
    },
  },
  mounted() {
    if (window.ethereum) {
      this.provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    } else {
      //alert("Please install MetaMask!");
    }
  },
  methods: {
    async openModal(type) {
      console.log(type);
      if (type == "walletDisconnectedModal") {
        this.showDisconnetModal = true;
      } else if (type == "mintingInProgressModal") {
        this.showmintingInProgressModal = true;
      } else if (type == "mintingSuccessfulModal") {
        this.showmintingSuccessfulModal = true;
      } else if (type == "alreadyMintedModal") {
        this.showalreadyMintedModal = true;
      } else if (type == "pleaseConnectWallet") {
        this.showPleaseConnectWalletModal = true;
      }
    },
    copy() {
      const VOTE_ADDR = "XXXXXXXXXXXXXXXXXXXX";
      const oInput = document.createElement("input");
      oInput.value = VOTE_ADDR;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand("Copy");
      oInput.remove();
      this.showMessage("Copied to clipboard");
      // this.$message.success('Copied to clipboard');
    },
    async closeModal(type) {
      if (type == "walletDisconnectedModal") {
        await this.revoke();
        this.showDisconnetModal = false;
      } else if (type == "mintingInProgressModal") {
        this.showmintingInProgressModal = false;
      } else if (type == "mintingSuccessfulModal") {
        this.showmintingSuccessfulModal = false;
      } else if (type == "alreadyMintedModal") {
        this.showalreadyMintedModal = false;
      } else if (type == "showPleaseConnectWalletModal") {
        this.showPleaseConnectWalletModal = false;
      }
    },
    async connectWallet() {
      try {
        if (window.ethereum) {
          // 默认支持MetaMask
          await this.switchToSepolia();
          await window.ethereum.request({ method: "eth_requestAccounts" });
          // this.provider  = new ethers.providers.Web3Provider(
          //   window.ethereum,
          //   "any"
          // );
          this.signer =  this.provider.getSigner();
          this.walletAddress = await this.signer.getAddress();

          // 创建合约实例
          this.contract = new ethers.Contract(
            "XXXXXXXXXXXXXXXXXXXX",
            MyToken.abi,
            this.signer
          );

          // 获取余额
          await this.fetchBalance();
          await this.updateMintProgress();
        } else {
          alert("Please install OKEx Wallet or MetaMask!");
        }
      } catch (error) {
        console.error("Failed to connect wallet:", error);
      }
    },
    selectAmount(amount) {
      this.selectedAmount = amount;
    },
    async disconnectWallet() {
      this.openModal("walletDisconnectedModal");
    },
    async revoke() {
        await window.ethereum
          .request({
            method: "wallet_revokePermissions",
            params: [
              {
                eth_accounts: {},
              },
            ],
          })
          .then(() => {
            setTimeout(function () {
              this.walletAddress = null;
              this.balance = 0;
              window.location.reload();
            }, 1000);
          })
          .catch((err) => {
            if (err.code === -32601) {
              this.walletAddress = null;
              this.balance = 0;
            }
          });
    },
    async switchToSepolia() {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [
            { chainId: ethers.utils.hexValue(parseInt(this.networkId)) },
          ],
        });
      } catch (switchError) {
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: ethers.utils.hexValue(parseInt(this.networkId)),
                  chainName: "Mainnet Test Network",
                  nativeCurrency: { name: "ETH", symbol: "ETH", decimals: 18 },
                  rpcUrls: ["https://mainnet.infura.io/v3/"], // 请替换为你的 Infura 项目 ID
                  blockExplorerUrls: ["https://etherscan.io"]
                },
              ],
            });
          } catch (addError) {
            console.error("Failed to add Sepolia network:", addError);
          }
        } else {
          console.error("Failed to switch network:", switchError);
        }
      }
    },
    async fetchBalance() {
      if (this.contract && this.walletAddress) {
        const balance = await this.contract.balanceOf(this.walletAddress);
        this.balance = ethers.utils.formatUnits(balance, 18);
      }
    },
    async updateMintProgress() {
      const totalSupply = await this.contract.HAS_MINT();
      this.totalMinted = ethers.utils.formatUnits(totalSupply, 18);
      console.log(totalSupply, this.totalMinted);
      this.percentMinted = (
        (this.totalMinted * 100) /
        this.maxSupply
      ).toString();
    },

    async mint() {
      if (this.walletAddress == null) {
        this.openModal("pleaseConnectWallet");
        return;
      }
      if (await this.hasMinted()) {
        this.openModal("alreadyMintedModal");
        return;
      }
      let mintPrice = ethers.utils.parseEther("0.0008") * this.selectedAmount;

      this.openModal("mintingInProgressModal");
      try {
        const mintAmount = this.selectedAmount;
        const tx = await this.contract.mint(mintAmount, { value: mintPrice });
        await tx.wait();
        this.closeModal("mintingInProgressModal");
        this.openModal("mintingSuccessfulModal");
        await this.updateMintProgress();
        await this.fetchBalance();
      } catch (error) {
        console.error("Minting failed:", error);
        this.showMessage(
          error.error != undefined
            ? error.error.message
            : "user rejected transaction"
        );
      } finally {
        this.closeModal("mintingInProgressModal");
      }
    },
    showMessage(message) {
      this.modalMessage = message;
      this.showModal = true;
    },
    closeModal1() {
      this.showModal = false;
      this.modalMessage = "";
    },
    async hasMinted() {
      const userBalance = await this.contract.balanceOf(this.walletAddress);
      return userBalance.gt(0);
    },
  },
};
</script>

<style scoped>
/* Add your styles here */
@import url("https://fonts.googleapis.com/css2?family=Anton&family=DynaPuff:wght@400..700&family=Patrick+Hand&display=swap");
@import "./assets/styles/iconfont.css";
@import "./assets/styles/progress.css";
/* Add your Tailwind CSS customizations here if necessary */
.body {
  font-family: "Anton", cursive;
  background-color: #81c180;
  color: rgb(9, 9, 9);
  font-size: 24px;
  min-height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.scrolling-text {
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  animation: marquee 20s linear infinite;
  font-size: 1.3rem;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
}
@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.separator {
  border-bottom: 2px solid black;
  margin: 20px 0;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.full-width-img {
  width: 100%;
  height: 100%;
}
.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
h1 {
  font-family: "Arial Black", sans-serif;
  font-size: 4rem;
  color: white;
  text-shadow: 2px 2px 0 #000, 4px 4px 0 #000;
  margin: 1rem 0;
}
.full-width-img {
  width: 45%;
  height: auto;
}

.separator {
  border-bottom: 2px solid black;
  margin: 20px 0;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.separator p {
  margin: 0 20px;
}

.flex-content {
  display: flex;
}

.hide-ele {
  display: none;
}

.copy-btn {
  position: absolute;
  height: 20px;
  width: 20px;
  background: url("./assets/copy.png") no-repeat center center;
}

@media screen and (max-width: 600px) {
  .flex-content {
    flex-direction: column;
  }
  .Connect-Wallet {
    font-size: 17px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  #disconnect {
    font-size: 17px !important;
  }
}
.number-button {
  border: none;
  color: white;
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.number-selector {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.number-button:hover {
  background-color: #fcae05;
}
.selected {
  border: 2px solid #fcae05;
  color: white;
  background-color: #fcae05 !important;
}
.bg1 {
  background-color: #49da7e;
}
.bg2 {
  background-color: #30c768;
}
.bg3 {
  background-color: #23b659;
}
.bg4 {
  background-color: #1c994a;
}
.bg5 {
  background-color: #087f34;
}
.active {
  background-color: #fcae05;
}

.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #09f;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-top: 10px;
  animation: spin 1s linear infinite;
}
.mint {
  color: #23b659;
}

/* Common Modal CSS */
.modal {
  display: none;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  text-align: center;
  border-radius: 10px;
  font-family: "Arial", sans-serif;
  color: #333;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* Specific Modal Content CSS */
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Custom styles matching your website design */
.modal-content {
  background-color: #f0f0f0;
  color: #333;
  font-family: "Comic Sans MS", sans-serif;
}

h2 {
  color: #4caf50;
}

p {
  /* color: #666; */
}

.example-button {
  margin: 10px;
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.example-button:hover {
  background-color: #45a049;
}
</style>
